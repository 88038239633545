import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useStorage } from '@vueuse/core'
import { useAPI, useFile } from '@/composables'

const STORAGE_KEY = 'NAZ_TOKEN'

export const useAuthentificationStore = defineStore('useAuthentificationStore', () => {
  const token = useStorage(STORAGE_KEY, '')
  const loggedUser = useStorage('NAZ_ME', {})
  const API = useAPI()
  const LogoFile = useFile()
  const files = useStorage('NAZ_ME_FILES', {
    avatar: null
  })

  const isLogged = computed(() => {
    return token.value !== ''
  })

  const isAdmin = computed(() => {
    return (loggedUser.value.login) ? loggedUser.value.login.includes('ZBS') : false
  })

  const isContact = computed(() => {
    return (loggedUser.value.login) ? loggedUser.value.login.includes('CBS') : false
  })

  const login = async (username, password) => {
    const response = await API.fetch('/login', {
      method: 'POST',
      data: {
        username,
        password
      },
      version: ''
    })
    if (response.data.token) {
      setToken(response.data.token)
      fetchUser()
    }
    return response
  }

  const fetchUser = async () => {
    const response = await API.fetch('/me', { method: 'get' })
    loggedUser.value = response.data
    fetchUserAvatar()
    return response
  }

  const fetchUserAvatar = async () => {
    if (loggedUser.value._links && loggedUser.value._links.avatar && loggedUser.value._links.avatar.href) {
      setAvatar(await LogoFile.fetch(loggedUser.value._links.avatar.href))
    }
  }

  const setAvatar = (value) => {
    files.value.avatar = value
  }

  const setToken = (value) => {
    token.value = value
    localStorage.setItem(STORAGE_KEY, token)
  }

  const disconnect = () => {
    token.value = ''
    loggedUser.value = {}
    localStorage.removeItem(STORAGE_KEY)
  }

  return {
    loggedUser,
    files,
    token,
    isLogged,
    isAdmin,
    isContact,
    login,
    fetchUser,
    setAvatar,
    setToken,
    disconnect,
    LogoFile
  }
})
