import { computed, ref } from 'vue'
import moment from 'moment'
import useAPI from '@/composables/useApi'

moment.locale('fr')

export const useAddress = () => {
  const API = useAPI()
  const address = ref({})
  const fetch = async (url) => {
    const response = await API.fetch(url, {
      method: 'get'
    })
    set(response.data)
    if (!address.value.country) {
      address.value.country = { id: 75 }
    }
    await fetchCountry()
    return address.value
  }

  const fetchCountry = async () => {
    if (address.value && address.value._links.country) {
      const response = await API.fetch(address.value._links.country.href, { method: 'get' })
      setCountry(response.data)
      return response
    }
  }

  const set = (data) => {
    address.value = data
  }

  const setCountry = (data) => {
    address.value.country = data
  }

  const updateMain = (value) => {
    address.value.main = value
  }

  const updateAdditional = (value) => {
    address.value.additional = value
  }

  const updateZipCode = (value) => {
    address.value.zipCode = value
  }

  const updateCity = (value) => {
    address.value.city = value
  }

  const updateArea = (value) => {
    address.value.area = value
  }

  const updateCountry = async (id) => {
    const response = await API.fetch('/country/' + id, { method: 'get' })
    setCountry(response.data)
  }

  const update = (data) => {
    updateMain(data.main)
    updateAdditional(data.additional)
    updateZipCode(data.zipCode)
    updateCity(data.city)
    updateArea(data.area)
    if (address.value.country && address.value.country.id !== data.country.id) {
      updateCountry(data.country.id)
    }
  }

  const reset = () => {
    set({})
  }

  const getAddress = computed(() => address.value)

  return {
    fetch,
    fetchCountry,
    set,
    update,
    reset,
    getAddress
  }
}

export default useAddress
