<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 960 960">
  <rect class="st1" width="960" height="960"/>
  <path class="st0" d="M0,960v-15.2s40.4-65.5,166.9-101c126.5-35.5,207.2-101.9,207.2-170.2,2.2-26.6-6.5-53-24-73.1-24.3-23.2-37.4-55.7-36-89.3,0,0-45.1-12.6-45.1-69.7s20.3-55.1,20.3-55.1c-6.2-32-9.6-64.6-10.1-97.2,0-48.6,12-169.2,195.2-169.2h12c183.4,0,195.2,120.6,195.2,169.2-.5,32.6-3.8,65.2-10.1,97.2,0,0,19.6-2.4,19.6,55.1s-45.1,69.7-45.1,69.7c1.4,33.6-11.7,66.1-36,89.3-17.5,20.1-26.2,46.5-24,73.1,0,68.3,80.8,134.6,207.2,170.2s166.7,101,166.7,101v15.2H0Z"/>
</svg>
</template>

<style scoped>
.st0 {
  fill: currentColor;
}
.st1 {
  fill: #ededed;
}
</style>
